<template>
  <!-- BODY -->
  <div v-if="businessData !== null">
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <form-wizard
        color="#00A5AF"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3 p-3"
        @on-complete="handleSubmit(updateBusiness)"
      >
        <tab-content
          :before-change="()=>{
            if($refs.commercialDetails.$data.flags.invalid)
              $refs.commercialDetails.handleSubmit();
            return !$refs.commercialDetails.$data.flags.invalid
          }"
          title="Commercial Details"
        >
          <validation-observer
            ref="commercialDetails"
            tag="form"
          >
            <commercial-details
              :commercial-details="businessData"
              :classifications="trimmedClassifications"
              :sub-classifications="subClass"
              :class-id.sync="classID"
              :languages="languages"
            />
          </validation-observer>
          <div class="btn-back">
            <back />
          </div>
        </tab-content>
        <tab-content
          :before-change="()=>{
            if($refs.commercialSocial.$data.flags.invalid)
              $refs.commercialSocial.handleSubmit();
            return !$refs.commercialSocial.$data.flags.invalid
          }"
          title="Social"
        >
          <validation-observer
            ref="commercialSocial"
            tag="form"
          >
            <commercial-social
              :commercial-details="businessData"
              :tag-options="tags"
              :donation-categories="donationCategories"
            />
          </validation-observer>
        </tab-content>
        <upload-progress :progress="progress" />
      </form-wizard>
    </validation-observer>
  </div>
</template>

<script>
import {
  computed,
} from '@vue/composition-api'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { FormWizard, TabContent } from 'vue-form-wizard'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'

import commercialDetails from '@/common/components/Business/Edit/CommercialDetails.vue'
import commercialSocial from '@/common/components/Business/Edit/CommercialSocial.vue'
import UploadProgress from '@/common/components/common/Organizations/UploadProgress.vue'

import getBusiness from '@/common/compositions/Business/getBusiness'
import getOrganizationDetails from '@/common/compositions/GeneralSettings/getGeneralSettings'
import handleFormData from '@/common/compositions/common/handleFormData'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import uploadPercentage from '@/common/compositions/common/uploadPercentage'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'EditBusiness',
  components: {
    FormWizard,
    TabContent,
    commercialDetails,
    commercialSocial,
    UploadProgress,
    Back,
  },
  data() {
    return {
      autocomplete: null,
      place: null,
      newBusinessData: {},
      businessData: null,
      classID: null,
    }
  },
  computed: {
    trimmedClassifications: {
      get() {
        return this.classifications.filter(element => element.name !== 'Organization')
      },
    },
  },
  watch: {
    classID() {
      this.getSubClassByClassId()
    },
  },
  created() {
    this.setBusinessData()
  },
  setup() {
    const { progress, calculateUploadPercentage } = uploadPercentage()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const {
      showErrors,
      successfulOperationAlert,
    } = handleAlerts()

    const { formData, setFormData } = handleFormData()

    const { getBusinessData } = getBusiness()

    const {
      languages,
      tags,
      donationCategories,
      classifications,
    } = getOrganizationDetails()

    const subClass = computed(() => store.getters['generalSettings/getSubClassifications'])

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      donationCategories,
      languages,
      tags,
      classifications,
      subClass,
      getBusinessData,
      formData,
      setFormData,
      showErrors,
      successfulOperationAlert,
      progress,
      calculateUploadPercentage,
    }
  },
  methods: {
    updateBusiness() {
      this.setNewBusinessData()
      this.removeImageParamIfNotEdited('logo')
      this.removeImageParamIfNotEdited('cover')
      this.setFormData(this.newBusinessData)
      this.$entities.post(`internalops/commercial_entity/${this.$router.currentRoute.params.id}?_method=PUT`, this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => { this.calculateUploadPercentage(progressEvent) },
      }).then(() => {
        this.successfulOperationAlert('Updated Successfully')
        this.$router.push('/business/list')
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
    setBusinessData() {
      this.getBusinessData().then(res => {
        this.businessData = res
        this.classID = this.businessData.classification[0].id
        this.getSubClassByClassId()
      })
    },
    getSubClassByClassId() {
      this.$store.dispatch('generalSettings/getClassSubClassificationRequest', this.classID)
    },
    setNewBusinessData() {
      this.newBusinessData = {
        id: this.$router.currentRoute.params.id,
        name: this.businessData.name,
        lat: this.businessData.location.coordinates[1],
        lng: this.businessData.location.coordinates[0],
        logo: this.businessData.logo,
        cover: this.businessData.cover,
        about: this.businessData.about,
        phone_number: this.businessData.phone_number,
        email: this.businessData.email,
        social_facebook: this.businessData.social_facebook,
        social_twitter: this.businessData.social_twitter,
        social_instagram: this.businessData.social_instagram,
        social_youtube: this.businessData.social_youtube,
        website: this.businessData.website,
        street: this.businessData.address[0].street,
        city: this.businessData.address[0].city,
        state: this.businessData.address[0].state,
        country: this.businessData.address[0].country,
        postal_code: this.businessData.address[0].postal_code,
        // to be discussed
        media_files: this.getUrlOfImageObjects(this.businessData.media_files),
        class_id: this.convertArrayOfObjectsToArrayOfIntegers(this.businessData.classification)[0],
        sub_class_id: this.convertArrayOfObjectsToArrayOfIntegers(this.businessData.subClassifications)[0],
        language_id: this.convertArrayOfObjectsToArrayOfIntegers(this.businessData.languages),
        tag_id: this.convertArrayOfObjectsToArrayOfIntegers(this.businessData.tags),
        donation_cats_id: this.convertArrayOfObjectsToArrayOfIntegers(this.businessData.donation_categories),
      }
    },
    removeImageParamIfNotEdited(image) {
      if (!this.isImageEdited(image)) {
        delete this.newBusinessData[image]
      }
    },
    isImageEdited(image) {
      return this.isImageBase64(image) || this.isImageNull(image)
    },
    isImageBase64(image) {
      return this.newBusinessData[image]?.includes('data:image')
    },
    isImageNull(image) {
      return this.newBusinessData[image] === '\'\''
    },
    convertArrayOfObjectsToArrayOfIntegers(attribute) {
      return typeof attribute[0] === 'object'
        ? attribute.map(x => x.id)
        : attribute
    },
    getUrlOfImageObjects(imagesArray) {
      return imagesArray.map(image => image.url ?? image)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
